import axios from "axios";
import { baseURL } from "../constants/actionsTypes";

const url = baseURL;

export const loginAuth0 = (username, password) => {
  return axios.post(`${url}/auth/login`, { username, password });
};

export const signUpUser = (data) => {
  return axios.post(`${url}/auth/signup`, data);
};

export const signUpCarehome = (data) => {
  return axios.post(`${url}/auth/signup-carehome`, data);
};

export const forgotPassword = (data) => {
  return axios.post(`${url}/auth/forgot-password`, data);
};

export const authMe = (accessToken) => {
  return axios.get(`${url}/auth/me`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const patientsFilterCancel = axios.CancelToken.source();
//roles request
export const fetchRoles = () => {
  return axios.get(`${url}/roles`);
};

//facilities request
export const fetchFacilities = (accessToken) => {
  return axios.get(`${url}/facilities?PageSize=10000000`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const fetchAllFacilities = () => {
  // console.log(`${url}/get-all-facilities?PageSize=10000000`);
  return axios.get(`${url}/facilities/get-all-facilities`);
};

// export const getFacilities = (accessToken) => {
//   return axios.get(`${url}/facilities`, {
//     headers: {
//       Authorization: `Bearer ${accessToken}`,
//       //Groups: userInfo.groups,
//     },
//   },
//   );
// };

export const createFacility = (facilityInfo, accessToken) => {
  return axios.post(`${url}/facilities`, facilityInfo, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateFacility = (facilityInfo, id, accessToken) => {
  return axios.put(`${url}/facilities/${id}`, facilityInfo, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const deleteFacility = (id, accessToken) => {
  return axios.delete(`${url}/facilities/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

//patients requests
export const fetchPatients = (
  accessToken,
  filter,
  facility,
  cancellationToken = null
) => {
  let _url = `${url}/patients`;

  if (facility) {
    _url = `${_url}?filter=${filter}&facility=${facility}`;
  }

  return axios.get(_url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const fetchPatientsByDate = (
  accessToken,
  facility,
  date = null,
  cancellationToken = null
) => {
  let _url = `${url}/patients?filterDate=${date}`;

  if (facility) {
    _url = `${_url}&facility=${facility}`;
  }

  return axios.get(_url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getPatient = (id, accessToken, cancellationToken = null) => {
  return axios.get(`${url}/patients/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getInactivePatient = (inactive, accessToken) => {
  return axios.get(`${url}/patients?includeInactive=${inactive}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: patientsFilterCancel.token,
  });
};

export const getTreatmentSummaryCancel = axios.CancelToken.source();
export const getTreatmentSummary = (id, accessToken) => {
  return axios.get(`${url}/patients/${id}/details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: getTreatmentSummaryCancel.token,
  });
};

export const getProgressNotesHistory = (id, accessToken, cancellationToken) => {
  return axios.get(`${url}/progress-notes/${id}/logs`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const deleteTreatment = (residentId, treatmentId, accessToken) => {
  return axios.delete(
    `${url}/patients/${residentId}/progress-notes/${treatmentId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const createPatient = (patientInfo, accessToken) => {
  return axios.post(`${url}/patients`, patientInfo, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const postPatientStatus = (patientId, patientStatus, accessToken) => {
  return axios.post(
    `${url}/patients/${patientId}/update-status`,
    patientStatus,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        //Groups: userInfo.groups,
      },
    }
  );
};

export const updatePatient = (patientInfo, id, accessToken) => {
  return axios.put(`${url}/patients/${id}`, patientInfo, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const deletePatient = (id, accessToken) => {
  return axios.delete(`${url}/patients/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getProgressNotes = (id, accessToken) => {
  return axios.get(`${url}/patients/${id}/details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getProgressNotesByDate = (
  residentId,
  date,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(
    `${url}/progress-notes/get-by-date?resident=${residentId}&date=${date}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        //Groups: userInfo.groups,
      },
      cancelToken: cancellationToken,
    }
  );
};

export const getGeneralCareHomeNotes = (
  residentId,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/progress-notes?residentId=${residentId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getPendingNotes = (date, accessToken) => {
  return axios.get(`${url}/progress-notes/pending-notes?date=${date}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const addProgressNotes = (id, data, accessToken) => {
  return axios.post(`${url}/patients/${id}/progress-notes`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const saveProgressNotes = (data, accessToken) => {
  return axios.post(`${url}/progress-notes/save`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
      //Groups: userInfo.groups,
    },
  });
};

export const editProgressNotes = (id, note, accessToken) => {
  return axios.put(`${url}/progress-notes/${id}`, note, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
      //Groups: userInfo.groups,
    },
  });
};

export const removeProgressNotes = (id, accessToken) => {
  return axios.delete(`${url}/progress-notes/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateProgressNotes = (id, note, accessToken) => {
  return axios.put(`${url}/patients/${id}/progress-notes`, note, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const deleteProgressNotes = (id, item, accessToken) => {
  return axios.delete(`${url}/patients/${id}/progress-notes`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    data: item,
  });
};

export const getTreatmentTypeDetails = (
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/utils/get-treatment-type-details`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getTreatmentTypes = (accessToken, cancellationToken = null) => {
  return axios.get(`${url}/utils/treatment-types`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getAssessmentTypes = (accessToken, cancellationToken = null) => {
  return axios.get(`${url}/utils/assessment-types`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getConsentTypes = (accessToken, cancellationToken = null) => {
  return axios.get(`${url}/utils/consent-types`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getNoTreatmentTypes = (accessToken, cancellationToken = null) => {
  return axios.get(`${url}/utils/no-treatment-types`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

// observations api
export const getObservations = (id, accessToken) => {
  return axios.get(`${url}/patients/${id}/observations`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const addObseervation = (id, data, accessToken) => {
  return axios.post(`${url}/patients/${id}/observations`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateObservation = (id, note, itemId, accessToken) => {
  return axios.put(`${url}/patients/${id}/observations/${itemId}`, note, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const deleteObservations = (id, itemId, accessToken) => {
  return axios.delete(`${url}/patients/${id}/observations/${itemId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getAssessments = (id, accessToken, cancellationToken = null) => {
  return axios.get(`${url}/patients/${id}/assessments`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const postAssessment = (id, postData, accessToken) => {
  return axios.post(`${url}/patients/${id}/assessments`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateAssessment = (id, itemId, postData, accessToken) => {
  return axios.put(`${url}/patients/${id}/assessments/${itemId}`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getAssessmentHistory = (
  id,
  itemId,
  accessToken,
  cancellationToken
) => {
  return axios.get(`${url}/patients/${id}/assessments/${itemId}/history`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getPhysioAssessments = (
  practice,
  id,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/assessments/${practice}?resident=${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const postPhysioAssessment = (practice, postData, accessToken) => {
  return axios.post(`${url}/assessments/${practice}`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updatePhysioAssessment = (
  practice,
  assessmentId,
  postData,
  accessToken
) => {
  return axios.put(`${url}/assessments/${practice}/${assessmentId}`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getAssessmentHistoryByPractice = (
  practice,
  assessmentId,
  accessToken,
  cancellationToken
) => {
  return axios.get(`${url}/assessments/${practice}/${assessmentId}/logs`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getDieteticsAssessments = (
  practice,
  id,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/assessments/${practice}?resident=${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const postDieteticsAssessment = (practice, postData, accessToken) => {
  return axios.post(`${url}/assessments/${practice}`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateDieteticsAssessment = (
  practice,
  assessmentId,
  postData,
  accessToken
) => {
  return axios.put(`${url}/assessments/${practice}/${assessmentId}`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getCarePlans = (id, accessToken, cancellationToken) => {
  return axios.get(`${url}/patients/${id}/care-plans`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const postCarePlan = (id, postData, accessToken) => {
  return axios.post(`${url}/patients/${id}/care-plans`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateCarePlan = (id, itemId, postData, accessToken) => {
  return axios.put(`${url}/patients/${id}/care-plans/${itemId}`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getCarePlanHistory = (
  id,
  itemId,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/patients/${id}/care-plans/${itemId}/history`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

//users api post
export const getAllUsers = (accessToken) => {
  return axios.get(`${url}/users`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getUser = (id, accessToken) => {
  return axios.get(`${url}/users/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getUserPhoto = (userId, accessToken) => {
  return axios.get(`${url}/users/get-profile/${userId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateUserPhoto = (data, userId, accessToken) => {
  return axios.put(`${url}/users/update-photo/${userId}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const createUser = (userInfo) => {
  return axios.post(`${url}/users`, userInfo);
};

export const updateUser = (userInfo, id, accessToken) => {
  return axios.put(`${url}/users/${id}`, userInfo, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

//loved 1s api
export const getAllUsersLovedOnes = (id, accessToken) => {
  return axios.get(`${url}/users/${id}/loved1s`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const updateUsersLoved1s = (data, id, accessToken) => {
  return axios.put(
    `${url}/users/${id}/loved1s`,
    {
      loved1s: data,
    },
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getAppointmentsToday = (dateFrom, dateTo, accessToken) => {
  return axios.get(`${url}/schedules/appointments-today`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getSchedules = (
  dateFrom,
  dateTo,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/schedules?dateFrom=${dateFrom}&dateTo=${dateTo}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
    cancelToken: cancellationToken,
  });
};

export const getSchedulesForFacilityTreatmentVisit = (
  accessToken,
  dateFrom,
  dateTo,
  treatmentId,
  isCarehomemanager = false,
  cancellationToken = null
  // email,
) => {
  return axios.get(
    `${url}/schedules/treatment-visits?fromDate=${dateFrom}&toDate=${dateTo}&treatmentId=${treatmentId}&isCarehomemanager=${isCarehomemanager}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      cancelToken: cancellationToken,
    }
  );
};

export const getScheduledVisits = (dateToday, accessToken) => {
  return axios.get(`${url}/schedules/visits?date=${dateToday}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getPerformedVisits = (
  dateFrom,
  dateTo,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(
    `${url}/schedules/performed-visits?fromDate=${dateFrom}&toDate=${dateTo}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        //Groups: userInfo.groups,
      },
      cancelToken: cancellationToken,
    }
  );
};

export const getAllPerformedVisits = (
  dateFrom,
  dateTo,
  pageNumber,
  pageSize,
  accessToken
) => {
  return axios.get(
    `${url}/schedules/performed-visits?fromDate=${dateFrom}&toDate=${dateTo}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        //Groups: userInfo.groups,
      },
    }
  );
};

export const postFinalizeVisits = (postData, accessToken) => {
  return axios.post(`${url}/schedules/finalize-visit`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getHandoverFromVisits = (visitId, accessToken) => {
  return axios.get(`${url}/schedules/visits/${visitId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const postHandoverSignature = (visitId, postData, accessToken) => {
  return axios.post(`${url}/schedules/visits/${visitId}`, postData, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getActivityType = (type, accessToken) => {
  return axios.get(`${url}/utils/${type}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getAnnouncements = (accessToken) => {
  return axios.get(`${url}/schedules/announcements`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const postClockInAndOut = (data, accessToken) => {
  return axios.post(`${url}/schedules/clock-in-out`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getClockInStatus = (accessToken) => {
  return axios.get(`${url}/schedules/clockin-status`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getAllergies = (accessToken, cancellationToken) => {
  return axios.get(`${url}/allergies`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancellationToken,
  });
};

export const addAllergy = (data, accessToken) => {
  return axios.post(`${url}/allergies`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateAllergy = (id, data, accessToken) => {
  return axios.put(`${url}/allergies/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const deleteAllergy = (id, accessToken) => {
  return axios.delete(`${url}/allergies/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getMedication = (accessToken, cancellationToken = null) => {
  return axios.get(`${url}/medications`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancellationToken,
  });
};

export const addMedication = (data, accessToken) => {
  return axios.post(`${url}/medications`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateMedication = (id, data, accessToken) => {
  return axios.put(`${url}/medications/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const deleteMedication = (id, accessToken) => {
  return axios.delete(`${url}/medications/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getPDRContacts = (facilityId, accessToken) => {
  return axios.get(`${url}/notification/contacts?facilityId=${facilityId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postContact = (data, accessToken) => {
  return axios.post(`${url}/notification/contacts/save`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const sendEmailPdr = (data, accessToken) => {
  return axios.post(`${url}/notification/email/send`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getPushNotification = (accessToken) => {
  return axios.get(`${url}/notification/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const generatePDR = (visitId, accessToken) => {
  return axios.get(`${url}/visits/generate-pdr?visitId=${visitId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postConsentForPodiatry = (data, accessToken) => {
  return axios.post(`${url}/consents`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getConsentForPodiatry = (
  residentId,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/patients/${residentId}/consents`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancellationToken,
  });
};

export const getConsent = (
  consentId,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/consents/${consentId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancellationToken,
  });
};

export const sendConsentToContact = (data, accessToken) => {
  return axios.post(`${url}/consents/send`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateConsent = (consentId, data) => {
  return axios.put(`${url}/consents/${consentId}`, data);
};

export const getWards = (accessToken, facilityId) => {
  return axios.get(`${url}/wards/${facilityId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getFacilityWards = (accessToken, facilityIds) => {
  return axios.get(`${url}/wards/per-facility/${facilityIds}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postWard = (facilityId, accessToken) => {
  return axios.post(`${url}/wards`, facilityId, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postTasks = (data, accessToken) => {
  return axios.post(`${url}/tasks`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getTasks = (accessToken) => {
  return axios.get(`${url}/tasks`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getTasksByFilter = (subject, startDate, endDate, accessToken) => {
  return axios.get(
    `${url}/tasks?subject=${subject}&startDate=${startDate}&endDate=${endDate}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const updateTask = (data, taskId, accessToken) => {
  return axios.put(`${url}/tasks/${taskId}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const doneTheTask = (taskId, data, accessToken) => {
  return axios.put(`${url}/tasks/${taskId}/done`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const deferredTheTask = (taskId, data, accessToken) => {
  return axios.put(`${url}/tasks/${taskId}/deferred`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getLovedOneContact = (
  residentId,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/contacts?resident=${residentId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancellationToken,
  });
};

export const postLovedOneContact = (data, accessToken) => {
  return axios.post(`${url}/contacts`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateLovedOneContact = (contactId, data, accessToken) => {
  return axios.put(`${url}/contacts/${contactId}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const deleteLovedOneContact = (contactId, accessToken) => {
  return axios.delete(`${url}/contacts/${contactId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postEmailLovedOneContact = (data, accessToken) => {
  return axios.post(`${url}/contacts/send`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getPractices = (accessToken) => {
  return axios.get(`${url}/practices`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postBooking = (data, accessToken) => {
  return axios.post(`${url}/bookings`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      //Groups: userInfo.groups,
    },
  });
};

export const getBookings = (residentId, dateFrom, dateTo, accessToken) => {
  return axios.get(
    `${url}/bookings?residentId=${residentId}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        //Groups: userInfo.groups,
      },
    }
  );
};

export const getBookingsAvailableByDate = (dateFrom, dateTo, accessToken) => {
  return axios.get(
    `${url}/bookings/available-dates?dateFrom=${dateFrom}&dateTo=${dateTo}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const postBookScheduledItem = (data, accessToken) => {
  return axios.post(`${url}/schedules/book-schedule`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postPractitionerSchedule = (data, accessToken) => {
  return axios.post(`${url}/schedules/create-practitioner-schedule`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updatePractitionerSchedule = (accessToken, scheduledId, data) => {
  return axios.put(`${url}/schedules/${scheduledId}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getPractitionerAvailability = (
  dateFrom,
  dateTo,
  email,
  bookingTypeId,
  accessToken
  // cancellationToken = null
) => {
  return axios.get(
    `${url}/schedules/practitioner-availability?fromDate=${dateFrom}&toDate=${dateTo}&email=${email}&bookingTypeId=${bookingTypeId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      // cancelToken: cancellationToken,
    }
  );
};

export const getResidentSubscription = (residentId, accessToken) => {
  return axios.get(`${url}/subscriptions/status?residentId=${residentId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getThreadAndParticipants = (accessToken) => {
  return axios.get(`${url}/threads`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getThreadsById = (threadId, accessToken) => {
  return axios.get(`${url}/threads/${threadId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getThreadMessagesByUser = (threadId, userId, accessToken) => {
  return axios.get(`${url}/threads/${threadId}/messages?user=${userId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getThreadMessagesByContact = (
  threadId,
  contactId,
  accessToken
) => {
  return axios.get(`${url}/threads/${threadId}/messages?contact=${contactId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const sendMessageToThread = (threadId, data, accessToken) => {
  return axios.post(`${url}/threads/${threadId}/messages`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postNewChannel = (data, accessToken) => {
  return axios.post(`${url}/chat`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const fetchChannelByLoggedInUser = (accessToken) => {
  return axios.get(`${url}/chat`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getAllMessagesFromChannel = (channelID, accessToken) => {
  return axios.get(`${url}/chat/${channelID}/messages`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const sendMessagesToChannel = (channelID, data, accessToken) => {
  return axios.post(`${url}/chat/${channelID}/messages`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

// export const getTreatmentNotesByPractice = (
//   accessToken,
//   practiceID,
//   facilityId
// ) => {
//   return axios.get(
//     `${url}/progress-notes/get-by-treatment-type/${practiceID}?facilityId=${facilityId}`,
//     {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     }
//   );
// };

export const getTreatmentNotesByPractice = (
  accessToken,
  practice,
  facilityId,
  cancellationToken
) => {
  return axios.get(
    `${url}/progress-notes/${practice}/all?facilityId=${facilityId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      cancelToken: cancellationToken,
    }
  );
};

export const getAssessmentsByPractice = (
  accessToken,
  practice,
  facilityId,
  cancellationToken
) => {
  return axios.get(
    `${url}/assessments/${practice}/all?facilityId=${facilityId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      cancelToken: cancellationToken,
    }
  );
};

export const getCarePlansByPractice = (
  accessToken,
  practice,
  facilityId,
  cancellationToken
) => {
  return axios.get(
    `${url}/careplans/${practice}/all?facilityId=${facilityId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      cancelToken: cancellationToken,
    }
  );
};

export const getFinalizeAndUnfinalizedHandovers = (
  accessToken,
  params,
  facilityIds
) => {
  return axios.get(
    `${url}/progress-notes/podiatrist/${params}?facilityId=${facilityIds}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const getLookupOptionByType = (accessToken, type) => {
  return axios.get(`${url}/lookup?type=${type}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getPendingLovedOneRequest = (accessToken) => {
  return axios.get(`${url}/loveones/getPendingLovedoneRequest`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const approveLovedOneRequest = (data, accessToken) => {
  return axios.post(`${url}/loveones/approvedRequest`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const deleteUserAccount = (accessToken) => {
  return axios.delete(`${url}/users`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const confirmBooking = (data) => {
  return axios.post(`${url}/schedules/confirm-booking?${data}`);
};

export const rejectBooking = (data) => {
  return axios.post(`${url}/schedules/reject-booking?${data}`);
};

export const startCheckout = (data) => {
  return axios.post(`${url}/payments/create-checkout-session`, data);
};

export const startCheckoutLovedOnesProgressNote = (data) => {
  return axios.post(
    `${url}/payments/create-lovedones-progress-note-checkout-session`,
    data
  );
};

export const checkBookingPayment = (data) => {
  return axios.get(
    `${url}/schedules/is-confirmation-paid?scheduleId=${data.bookingId}&residentId=${data.residentId}`
  );
};

export const getResidentFundingSource = (data) => {
  return axios.get(
    `${url}/schedules/get-resident-funding-source?email=${data.email}&residentId=${data.residentId}`
  );
};

export const requestToChangeFundingType = (data, accessToken) => {
  return axios.get(
    `${url}/utils/sent-email-request?practionerEmail=${data.practionerEmail}&practionerName=${data.practionerName}&residentName=${data.residentName}&facilityName=${data.facilityName}&fundingType=${data.fundingType}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const changeFundingType = (data, accessToken) => {
  return axios.post(`${url}/patients/change-resident-funding-source`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const addActiveResidentDate = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/patients/add-active-resident-date`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const setInActiveResidentDate = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/patients/set-resident-inactive`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllGPOrLA = (role, accessToken, cancellationToken = null) => {
  return axios.get(`${url}/${role}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancellationToken,
  });
};

export const getGPOrLAForResident = (
  role,
  residentId,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(`${url}/${role}/sharedWith/${residentId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    cancelToken: cancellationToken,
  });
};

export const addGPOrLA = (role, data, accessToken) => {
  return axios.post(`${url}/${role}/create`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateGPOrLA = (role, id, data, accessToken) => {
  return axios.put(`${url}/${role}/${id}`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const deactivateGPOrLA = (role, id, accessToken) => {
  return axios.put(
    `${url}/${role}/${id}/deactivate`,
    {},
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

export const sendEmailForHistory = (data, accessToken) => {
  return axios.post(`${url}/notification/email/send-history`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const checkProgressNotePayment = (data) => {
  return axios.get(
    `${url}/webhooks/is-lovedone-progress-notes-already-paid?userId=${data.userId}&residentId=${data.residentId}`
  );
};

export const getResidentLovedOnes = (
  residentId,
  accessToken,
  cancellationToken = null
) => {
  return axios.get(
    `${url}/loveones/get-lovedone-by-resident?residentId=${residentId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        //Groups: userInfo.groups,
      },
      cancelToken: cancellationToken,
    }
  );
};

export const changePaymentType = (data, accessToken) => {
  return axios.post(`${url}/patients/change-payment-type`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getSubscriptionStatus = (userId, residentId, accessToken) => {
  return axios.get(
    `${url}/payments/get-subscription-status?userId=${userId}&residentId=${residentId}`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
};

// Get a customer (if not existing create a new one)
export const getCustomer = (data, accessToken) => {
  return axios.post(`${url}/payments/get-customer`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getProduct = (productId, accessToken) => {
  return axios.get(`${url}/payments/get-product?productId=${productId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const createSubscription = (data, accessToken) => {
  return axios.post(`${url}/payments/create-subscription`, data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/x-www-form-urlencoded",
    },
  });
};

export const getFacilityRetainedService = (accessToken) => {
  return axios.get(`${url}/subscriptions/get-residents-per-carehome-manager`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getAllPractitioners = (accessToken) => {
  return axios.get(`${url}/practitioners`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
